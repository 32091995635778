@import "common/variables";

.blog-entry-overview {
  border-bottom: 2px solid #{$light-grey};
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  .image {
    margin-bottom: 3rem;
  }
  .content {
    text-align: justify;

    h3 {
      margin-bottom: 0.2rem;
    }
  }
  .meta {
    font-size: 1.2rem;
    margin-top: 3rem;
    .info {
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      border-right: 1px solid #000;
      &.no-border {
        border-right: none;
      }
    }
    .share {
      cursor: pointer;
      position: relative;

      .popup {

        background: #fff;
        box-shadow: 10px 10px 106px -24px rgba(0,0,0,0.75);
        position: absolute;
        bottom: 2rem;
        z-index: 100;
        width: 5rem;
        border: 1px solid $light-grey;
        ul, li {
          padding: 0;
          margin: 0;
          list-style: none;
          text-align: center;
          .facebook {
            background-color: #4a66b7;
          }
          .twitter {
            background-color: #00acee;
          }
          .linkedin {
            background-color: #1b86bc;
          }
          a {
            font-size: 3rem;
            display: inline-block;
            height: 5rem;
            width: 5rem;
            line-height: 5rem;
            color: #fff;
          }
        }
      }
    }
    .sources {
      margin-top: 1.5rem;
    }
    a {
      color: #000;
    }
  }
}

.pager {
  text-align: left;
  li {
    > a {
      font-size: 2rem;
      font-weight: bold;
      color: #000;
      border-radius: 0;
    }
    > span {
      font-size: 2rem;
      font-weight: bold;
      border-radius: 0;
      background-color: #000;
      border: 1px solid #000;
    }
  }
  .pagination {
    .li {
      a {
        border-radius: 0;
      }
    }
  }

}