/// System stack fonts for more efficient mobile rendering
/// @type Font family
$system-font: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;

/// The font used everywhere
/// @type Font Family
/// @require {variable} system-font <link>
$font-family: 'Ubuntu', #{$system-font};

$semi-black: #333;
$orange: #eb690b;
$blue: #009cff;
$light-grey: #e3e3e3;