@import "common/fonts";
@import "common/variables";
@import "common/mixins";

html,body {
  height:100%;
}

html {
  body {
    background: #000 url(/static/img/main-bg.jpg) top center no-repeat;
    background-size: 100%;
    font-family: $font-family;
    font-size: 1.5rem; // 15px
    min-height: 100%;
    padding-top: 10rem;
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }
    h1,h2 {
      margin-bottom: 3rem;
    }
    h3,h4,h5 {
      margin-bottom: 1.5rem;
    }
    a {
      color: $blue;
    }
    header {

      height: 12.5rem;
      @include breakpoint(xs) {
        z-index: 10000;
        height: auto;
      }
      .inside {
        background: #000;
        height: 12.5rem;
        padding-right: 1.5rem;
        @include breakpoint(xs) {
          height: auto;
        }
        position: relative;
        .logo {
          position: absolute;
          left: 3rem;
          z-index: 1000;
          img {
            margin-top: -1rem;
            height: 16rem;
          }
        }
        .navbar {
          background-color: #000;
          .navbar-brand {
            img {
              position: absolute;
              top: -0.1rem;
              left: 3rem;
              height: 50px;
            }
          }
          li {
            margin-top: 3.5rem;
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: lighter;
            a {
              color: #fff;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }

    #content {
      margin-top: 10rem;
      margin-bottom: 10rem;
      > div > div > .inner {
        padding: 3rem;
        background: #fff;
        > .row {
          > .content {
            //@include breakpoint(sm) {
            //  border-right: 2px solid #{$light-grey};
            //}
            @import "blog";
          }
          aside {
            height: 100%;
            @include breakpoint(xs) {
              margin-top: 3rem;
            }
            > .row {
              margin-bottom: 3rem;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
            .jessica {
              position: relative;
              background: #000;
              color: #fff;

              .logo {
                height: 7rem;
                margin-top: 2rem;
              }
              a {
                color: #fff;
                &:hover {
                  color: #fff;
                }
              }
              > .row {
                > .col-xs-6 {
                  position: relative;
                  height: 100%;
                }
                position: relative;
              }
              .social {
                position: absolute;
                bottom: 1.5rem;
                right: 3rem;
                cursor: pointer;
              }
              ul, li {
                list-style: none;
                margin: 0;
                padding: 0;
              }
              li {
                display: inline-block;
                margin-right: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

